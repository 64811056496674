.btn-container{
    position:absolute;
    display: flex;
    flex-direction: column;
    right: 25px;
    top: 1.5rem;
    gap: 5px;
}

.btn{
    border-radius:8px;
    border: 1px solid transparent;
    padding: 5px;
    font-size: 1em;
    background-color: rgb(20, 16,52);
     cursor: pointer;
     transition:  border-color 0.25s;
}

.btn:hover {
    border: 1px solid white;
}

.langue-img{
    width: 40px;
}

.btn.selected {
    background-color:#404154;
}

.intro__text{
    
}